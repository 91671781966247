import Vue from 'vue'
import Vuex from 'vuex'
import mTypes from './mutation_types'
import actions from './action_types'
import LoginService from '@/views/login/login.service'
import * as Security from '@/common/security_manager'
import {getCookie, setCookies} from "@/common/utils";
import LZString from 'lz-string';


Vue.use(Vuex)

const myStore = store => {
  // called when the store is initialized
  store.subscribe((mutation, state) => {
    if (state.current.user) {
      let cloneOfState = JSON.parse(JSON.stringify(state));
      delete cloneOfState.current.user_properties;

      // Compress the data
      const compressed = LZString.compress(JSON.stringify(cloneOfState));
      localStorage.setItem('store', compressed)
    }
  })
}

export default new Vuex.Store({
  state: {
    current: {
      user: null,
      property: null,
      hashed_password: null,
      business_day: null,
      permissions: null,
      enterprise_permissions: null,
      properties: null,
      hk_info: null,
      google_login: false,
      dynamic_url: null,
      user_properties: null,
      sso_login: false,
      sso_login_details: null,
      localization_keys: null,
    },
    triggerIntervalId: null
  },
  mutations: {
    [mTypes.SET_TRIGGER_INTERVAL_ID](state,payload) {
      state.triggerIntervalId = payload;
    },
    [mTypes.SET_CURRENT_USER] (state, payload) {
      state.current.user = payload
    },

    [mTypes.SET_HK_TOKEN] (state, payload) {
      //state.current.hk_token = payload
      localStorage.setItem('hk_token',payload);
    },
    [mTypes.SET_LOCALIZATION](state, value) {
      state.localization_keys = value;
    },
    [mTypes.SET_HK_INFO] (state, payload) {
      state.current.hk_info = payload
    },

    [mTypes.SET_CURRENT_PROPERTY] (state, payload) {
      state.current.property = payload
    },

    [mTypes.SET_CURRENT_USER_HASHED_PASSWORD] (state, payload) {
      state.current.hashed_password = payload
    },

    [mTypes.SET_CURRENT_USER_PROPERTIES] (state, payload) {
      state.current.properties = payload
    },

    [mTypes.SET_PROPERTIES_FROM_USER] (state, payload) {
      state.current.user_properties = payload
    },

    [mTypes.SET_CURRENT_BUSINESS_DAY] (state, payload) {
      state.current.business_day = payload
    },

    [mTypes.SET_PERMISSIONS] (state, payload) {
      state.current.permissions = payload
    },

    [mTypes.SET_ENTERPRISE_PERMISSIONS] (state, payload) {
      state.current.enterprise_permissions = payload
    },

    [mTypes.SET_DYNAMIC_URL] (state, payload) {
      state.current.dynamic_url = payload
    },

    [mTypes.SET_GOOGLE_LOGIN] (state, payload) {
      state.current.google_login = payload
    },

    [mTypes.SET_TOKEN_EXPIRY_DATE] (state, payload) {
      localStorage.setItem('hk_token_expiry',payload);
      //state.current.token_expiry_date = payload
    },

    [mTypes.SET_SSO_LOGIN] (state, payload) {
      state.current.sso_login = payload
    },

    [mTypes.SET_SSO_LOGIN_DETAILS] (state, payload) {
      state.current.sso_login_details = payload
    },

    [mTypes.RESET_CURRENT_PROPERTY](state) {
      state.current.property = null;
    },

    [mTypes.SET_CURRENT_TO_NULL] (state) {
      if (state.current) {
        state.current.user = null
        state.current.property = null
        state.current.hashed_password = null
        state.current.business_day = null
        state.current.permissions = null
        state.current.enterprise_permissions = null
        state.current.properties = null
        state.current.sso_login = false
        state.current.sso_login_details = null
        state.current.cognito_login = null
      }
    },
    [mTypes.INITIALIZE_STORE] (state) {
      if (localStorage.getItem('store')) {

        // Retrieve the compressed data from localStorage
        const compressed = localStorage.getItem('store');
        if (compressed) {
          // Decompress the data
          const decompressed = LZString.decompress(compressed);
          this.replaceState(
            Object.assign(state, JSON.parse(decompressed))
          )
        }
        // Replace the state object with the stored item


        /*const token_expiry = getCookie('hk_token_expiry')
        const token = getCookie('hk_token')
*/
        /*if (token && state.current.hk_token !== token ) {
          state.current.hk_token = token;
        }

        if (token_expiry && state.current.token_expiry_date !== token_expiry) {
          state.current.token_expiry_date = token_expiry;
        }*/

      }
    },
    [mTypes.SET_COGNITO_LOGIN] (state, payload) {
      state.current.cognito_login = payload
    },
  },
  actions: {
    [actions.login] ({ commit }, { username, password }) {
      return LoginService.login(username, password).then((response) => {
        let token = Security.getToken(username, password)

        commit(mTypes.SET_HK_TOKEN, token.token)
        commit(mTypes.SET_HK_INFO, token.info)
        commit(mTypes.SET_CURRENT_USER_HASHED_PASSWORD, token.hashed_password)
        commit(mTypes.SET_CURRENT_USER, response.data.user)
        commit(mTypes.SET_ENTERPRISE_PERMISSIONS, response.data.enterprise_permissions)
        commit(mTypes.SET_CURRENT_USER_PROPERTIES, response.data.properties)
        commit(mTypes.SET_PROPERTIES_FROM_USER, response.data.properties)

        setCookies(false);
      })
    },
    [actions.tfaLogin] ({ commit }, { response, username, password }) {
      let token = Security.getToken(username, password)

      commit(mTypes.SET_HK_TOKEN, token.token)
      commit(mTypes.SET_HK_INFO, token.info)
      commit(mTypes.SET_CURRENT_USER_HASHED_PASSWORD, token.hashed_password)
      commit(mTypes.SET_CURRENT_USER, response.data.user)
      commit(mTypes.SET_ENTERPRISE_PERMISSIONS, response.data.enterprise_permissions)
      commit(mTypes.SET_CURRENT_USER_PROPERTIES, response.data.properties)
      commit(mTypes.SET_PROPERTIES_FROM_USER, response.data.properties)

      setCookies(false);
    }
  },
  plugins: [myStore]
})
