<template>
  <div id="app">
    <router-view v-loading="loadingSSO"></router-view>
  </div>
</template>

<script>
import mutations from '@/store/mutation_types'
import LoginService from '@/views/login/login.service'
import {mapMutations, mapState} from 'vuex'
import * as Security from '@/common/security_manager'
import * as CryptoJS from 'crypto-js'
import cognitoTestData from '@/common/cognitoTestData.json';
import {localStorageEventListener, setCookies} from "@/common/utils";

export default {
  name: 'app',
  data() {
    return {
      loadingSSO: false
    }
  },
  mounted() {
    LoginService.initializeRefreshTokenTrigger();
    window.addEventListener('storage', localStorageEventListener);

  },
  methods: {
    ...mapMutations([
      mutations.SET_CURRENT_USER,
      mutations.SET_ENTERPRISE_PERMISSIONS,
      mutations.SET_CURRENT_USER_PROPERTIES,
      mutations.SET_PROPERTIES_FROM_USER,
      mutations.SET_GOOGLE_LOGIN,
      mutations.SET_HK_TOKEN,
      mutations.SET_HK_INFO,
      mutations.SET_HK_INFO,
      mutations.SET_SSO_LOGIN,
      mutations.SET_SSO_LOGIN_DETAILS,
      mutations.SET_COGNITO_LOGIN
    ]),
    enrollTfa(res) {
      // this[mutations.SET_GOOGLE_LOGIN](false)
      // this[mutations.SET_SSO_LOGIN](true)
      const bearerToken = `Bearer ${res.data.token}`
      // this[mutations.SET_HK_TOKEN](bearerToken)
      let tokenObj = Security.getTokenWithHashedPass(res.data.user.username, res.data.token)
      // this[mutations.SET_HK_INFO](tokenObj.info)
      // this[mutations.SET_CURRENT_USER](res.data.user)
      // this[mutations.SET_ENTERPRISE_PERMISSIONS](res.data.enterprise_permissions)
      // this[mutations.SET_PROPERTIES_FROM_USER](res.data.properties)
      // this[mutations.SET_CURRENT_USER_PROPERTIES](res.data.properties)
      LoginService.enrollTfa(bearerToken, tokenObj.info).then(resFromEnroll => {

        this.$router.push({
          name: 'qr-code',
          params: {
            qr_code_url: resFromEnroll.data.data,
            hkInfo: tokenObj.info,
            bearerToken,
            firstTime: true,
            username: res.data.user.username
          }
        })
      }).catch(err => {
        this.$notify.error({
          title: 'Error',
          message: err.response ? (err.response.data ? err.response.data.message : err.response) : err
        })
        this.$router.push({name: 'different-login'})
        throw err
      }).finally(() => {
        this.loadingSSO = false
      })
    },
    setMutations(res, tokenObject) {
      let tokenObj = tokenObject ? tokenObject : Security.getTokenWithHashedPass(res.data.user.username, res.data.token)
      const bearerToken = `Bearer ${res.data.token}`
      this[mutations.SET_GOOGLE_LOGIN](false)
      this[mutations.SET_SSO_LOGIN](true)
      this[mutations.SET_HK_TOKEN](bearerToken)
      this[mutations.SET_HK_INFO](tokenObj.info)
      this[mutations.SET_CURRENT_USER](res.data.user)
      this[mutations.SET_ENTERPRISE_PERMISSIONS](res.data.enterprise_permissions)
      this[mutations.SET_PROPERTIES_FROM_USER](res.data.properties)
      this[mutations.SET_CURRENT_USER_PROPERTIES](res.data.properties)
      setCookies(false);
    }
  },
  beforeCreate() {
    this.$store.commit(mutations.INITIALIZE_STORE)
    let title = document.querySelector('title')
    title.innerHTML = `${hkConfigEnvs.VUE_APP_NAME}`
  },
  computed: {
    ...mapState({
      user: state => state.current.user,
      triggerIntervalId: state => state.triggerIntervalId,
    })
  },
  beforeMount() {
    if (this.$route.query.pool_id &&
      this.$route.query.code &&
      this.$route.query.platform === "cognito") {

      this.loadingSSO = true
      const payload = {
        code_verifier: localStorage.getItem('CodeVerifier'),
        auth_code: this.$route.query.code
      }
      LoginService.loginWithCognito(payload, this.$route.query.pool_id, this.$route.query.app_id).then(res => {
        if (res.status === 231) {
          this.enrollTfa(res)
        } else if (res.status === 200) {
          this.setMutations(res)
          this.loadingSSO = false
          this[mutations.SET_COGNITO_LOGIN](true)
          this.$router.push({name: 'home'})
        }

      }).catch(err => {
        this.loadingSSO = false
        this.$notify.error({
          title: 'Error',
          message: err.response ? (err.response.data ? err.response.data.message : err.response) : err
        })
        this.$router.push({name: 'different-login'})
        throw err
      })
    } else if (this.$route.query.code &&
      hkConfigEnvs.VUE_APP_OAUTH_AUTH_URL === 'true' &&
      !this.user &&
      localStorage.getItem('CodeVerifier')) {
      // console.log('ssoLogin')
      this.loadingSSO = true
      let tokenObj
      const payload = {
        code_verifier: localStorage.getItem('CodeVerifier'),
        auth_code: this.$route.query.code
      }
      LoginService.loginWithSSO(payload).then(res => {
        this[mutations.SET_GOOGLE_LOGIN](false)
        this[mutations.SET_SSO_LOGIN](true)
        const bearerToken = `Bearer ${res.data.token}`
        this[mutations.SET_HK_TOKEN](bearerToken)
        tokenObj = Security.getTokenWithHashedPass(res.data.user.username, res.data.token)
        this[mutations.SET_HK_INFO](tokenObj.info)
        this[mutations.SET_CURRENT_USER](res.data.user)
        this[mutations.SET_ENTERPRISE_PERMISSIONS](res.data.enterprise_permissions)
        this[mutations.SET_PROPERTIES_FROM_USER](res.data.properties)
        setCookies(false)
        return LoginService.getProperties(bearerToken, tokenObj.info)
      }).then(properties => {
        this[mutations.SET_CURRENT_USER_PROPERTIES](properties.data)
        this.$router.push({name: 'home'})
      }).catch(err => {
        this.$notify.error({
          title: 'Error',
          message: err.response ? (err.response.data ? err.response.data.message : err.response) : err
        })
        this.$router.push({name: 'login'})
        throw err
      }).finally(() => {
        this.loadingSSO = false
      })
    } else {
      console.log("None of the conditions fullfilled. Reached last block")
    }

    if (this.$route.query.error_description) {
      this.$notify.error({
        title: 'Error',
        message: this.$route.query.error_description
      })

      console.error(this.$route.query.error_description)
    }

  },
  beforeDestroy() {
    window.removeEventListener('storage', localStorageEventListener)
    if (this.triggerIntervalId) {
      try {
        clearInterval(this.triggerIntervalId)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
}
</style>
