<template>
  <div @click="$emit('google-button-clicked')" class="g-sign-in-button" ref="signinBtn">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'g-signin-button',
  props: {
    params: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  mounted () {
    if (!window.gapi) {
      this.$log.debug('"https://apis.google.com/js/api:client.js" needs to be included as a script.')
      return
    }

    if (!this.params.client_id) {
      this.$log.debug('params.client_id must be specified.')
      return
    }

    window.gapi.load('auth2', () => {
      const auth2 = window.gapi.auth2.init(this.params)
      auth2.attachClickHandler(this.$refs.signinBtn, {}, googleUser => {
        this.$emit('success', googleUser)
      }, error => {
        this.$emit('error', error)
        this.$emit('failure', error) // an alias
      })
      auth2.attachClickHandler(this.$refs.signinBtn, {}, googleUser => {
        this.$emit('success', googleUser)
      })
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
