import VueI18n from 'vue-i18n';
import Vue from "vue";
import {getCookie} from '@/common/utils';

Vue.use(VueI18n);

const messages = {};
const i18n = new VueI18n({
    locale: getCookie('hk_language')?.substring(0, 2),
    fallbackLocale: 'en',
    messages,
});

export default i18n;
