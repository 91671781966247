import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/login/Login.vue'
import DifferentLogin from './views/login/DifferentLogin.vue'

import Home from './views/Home.vue'
import QrCodeScreen from './views/login/qrCodeScreen'
import {clearAllCookies, clearLocalStorage, getCookie} from '@/common/utils'
import LoginService from '@/views/login/login.service'
import store from '@/store/store'
import mutations from '@/store/mutation_types'

Vue.use(Router)

const router = new Router({
  // mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'loginWithSlash',
      component: Login
    },
    {
      path: 'app/login',
      name: 'loginWithSlash',
      component: Login
    },
    {
      path: '/qr-code',
      name: 'qr-code',
      component: QrCodeScreen
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/logout',
      name: 'logout'
    },
    {
      path: '*',
      component: Login
    },{
    path: '/different-login',
    name: 'different-login',
    component: DifferentLogin
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'logout') {

    if (getCookie('hk_logged_user')) {
      try {
        if (getCookie('hk_signed_in_as') === 'hotelkeyapp.com') {
          let googleSignInParams = {
            client_id: hkConfigEnvs.VUE_APP_GOOGLE_CLIENT_ID,
            hosted_domain: 'hotelkeyapp.com'
          }
          window.gapi.load('auth2', async () => {
            let initSignOut = await window.gapi.auth2.init(googleSignInParams)
            initSignOut.signOut()
            initSignOut.disconnect()
          })
        }

        await LoginService.logout().then(()=>{
          clearLocalStorage();
        });

      } catch (err) {
        console.error(err)
      } finally {

        store.commit(mutations.SET_CURRENT_TO_NULL)

        let username = getCookie('hk_logged_user')

        if (getCookie('hk_token')) {
          clearLocalStorage()
          clearAllCookies();
        }

        if (window.location.hostname === 'localhost') {
          try { next('/') } catch(e) {}
        } else {


          if (hkConfigEnvs.VUE_APP_LOGOUT_URL && username) {
            // window.location.href = hkConfigEnvs.VUE_APP_LOGOUT_URL
            window.location.href = `${hkConfigEnvs.VUE_APP_LOGOUT_URL}&username=${username}&state=HK_HOME`
          } else {
            //try { next('/') } catch(e) {}
            window.location.href = `${hkConfigEnvs.VUE_APP_LOGOUT_URL}`
          }

        }
      }
    } else {
      try { next('/') } catch(e) {}
    }

  } else {
    next()
  }

})
export default router
