<template>
<div>
  <el-card shadow="always" class="card">
    <div class="m-b-10">
      To set up Two Factor Authentication please follow below steps
    </div>
    <div class="m-b-5">
      Download Google Authenticator or Microsoft Authenticator App using below links
    </div>
    <div class="flexed-column m-b-10 gap-5">
      <a :href="googleIosHref" target="_blank">Download Google Authenticator for iOS</a>
      <a :href="googleAndroidHref" target="_blank">Download Google Authenticator for Android</a>
      <a :href="microsoftIosHref" target="_blank">Download Microsoft Authenticator for iOS</a>
      <a :href="microsoftAndroidHref" target="_blank">Download Microsoft Authenticator for Android</a>
    </div>
    <div class="m-b-20">
      Once Installed, Open the app and tap on the (+) icon and scan the QR code below
    </div>
    <div class="qr-code m-b-20"><qrcode-vue :value="$route.params.qr_code_url" size="150" level="H" /></div>
    <div class="flexed" name="verifyForm" role="form" novalidate>
      <el-input v-model="token" name="token" type="text" id="token" class="form-control"  @keypress.native="tokenOnKeypress($event)"
                placeholder="Enter your code here" required autofocus></el-input>

      <el-button :loading="loading" type="primary" style="margin-left: 5px;" @click="verify()" >Verify</el-button>
    </div>
  </el-card>
  <!-- <el-dialog
    :visible.sync="dialogVisible"
    :show-close="false"
    width="30%">
    <span>You have successfully setup Two factor Authentication for your account. Please login again.</span>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogVisible = false">OK</el-button>
    </span>
  </el-dialog> -->
</div>
</template>

<script>

import LoginService from '@/views/login/login.service'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'qrCodeScreen',
  components: {
    QrcodeVue,
  },
  beforeMount () {
     if(!this.$route.params.bearerToken) this.$router.push({name: 'different-login'})
  },
  data () {
    return {
      loading: false,
      token: null,
      activeTab: 'googleAuth',
      dialogVisible: false
    }
  },
  computed: {
    microsoftAndroidHref() {
      return  "https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_IN&gl=US"
    },
    googleAndroidHref() {
      return  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US"
    },
    microsoftIosHref() {
      return "https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
    },
    googleIosHref() {
      return  "https://apps.apple.com/us/app/google-authenticator/id388497605"
    }
  },
  methods: {
    tokenOnKeypress (event) {
      if (event.keyCode === 13 || event.code == 'Enter') this.verify()
    },
    verify () {
      if (this.token) {
        let errorMsg
        var token = this.token || ''
        token = token.replace(/ /g, '') // remove spaces
        this.loading = true
        let payload = { data: token }
        LoginService.enrollTfaConfirm(this.$route.params.bearerToken, this.$route.params.hkInfo, payload ).then(() => {
          this.$alert('You have successfully setup Two factor Authentication for your account. Please login again.', {
            confirmButtonText: 'OK',
            showClose: false,
            callback: action => {
              if (window.location.hostname !== 'localhost' && hkConfigEnvs.VUE_APP_LOGOUT_URL) {
                window.location.href = `${hkConfigEnvs.VUE_APP_LOGOUT_URL}&username=${this.$route.params.username}&state=HK_HOME`
              } else {
                this.$router.push({name: 'different-login'})
              }
            }
            });
        }).catch((error) => {
          switch (error.response.status) {
            case 400:
              errorMsg = 'Invalid token'
              break
            case 401:
              errorMsg = 'Unauthorized'
              break
            case 403:
              errorMsg = 'Invalid username or password'
              break
            case 404:
              errorMsg = 'Resource not found'
              break
            case 500:
              errorMsg = 'Server error'
              break
            case 503:
              errorMsg = 'Server error'
              break
            case 423:
              errorMsg = 'Account Locked'
              break
            case 424:
              errorMsg = 'Account Inactive'
              break
            default :
              errorMsg = 'Login failed'
              break
          }
        if(errorMsg) this.$notify.error({ title: 'Error', message: errorMsg })
        }).finally(() => {this.loading = false})
      }
    }

  }
}
</script>

<style lang="scss" scoped>

  .card {
    max-width: 430px;
    margin: 60px auto 0 auto;
    a {
      font-size: 15px;
    }
    .qr-code{
      width: 100%;
      text-align: center;
    }
}

</style>
